import React, { useMemo, useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import {
  isWithinInterval,
  startOfWeek,
  setDay,
  isSameWeek,
  isBefore,
  isAfter,
  endOfWeek,
  differenceInDays,
  startOfDay,
  addDays,
  isSameDay
} from 'date-fns';
import { v4 } from 'uuid';
import { MdWarning } from 'react-icons/md';
import { TextInputLayout, InputLayoutVariant } from 'components/v3/InputLayout';
import { FormTable, FormTableTitle } from 'components/v3/Form/TableField';
import DateNavigation from 'components/v3/DateNavigation';
import { UiSelector } from 'features/UiPreferences';
import { Dashed, Icon, Text } from 'components/v3/ui';
import { parseDate, printDate, formatISODate } from 'utils/dates';
import { PurveyorSelect, ProductSelect, SurfacingAction } from 'features/Surfacing';
import sum from 'lodash/sum';
import Tooltip from 'components/v3/Tooltip';
import isSameIds from 'utils/idUtils';

const Wrapper = styled.div`
  width: 100%;
  .phased {
    border-bottom: 2px solid ${({ theme }) => theme.accent};
  }
`;

const MultilineRow = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  > *:not(only-child):not(:first-child) {
    margin-top: 4px;
  }
`;

const RepartitionText = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding-right: 8px;
  i {
    color: ${({ theme }) => theme.warning};
    cursor: help;
  }
  span {
    align-self: center;
  }
  span + i {
    margin-left: 8px;
  }
`;

export const orderRowCreator = () => ({
  purveyor: null,
  product: null,
  total: 0,
  isNew: true,
  id: v4()
});

const isRowValid = (row) => Boolean(row.purveyor) && Boolean(row.product);

const getTonnageColumn = (header, dates, week, weekDay, showDayAndNight) => {
  const date = startOfDay(
    setDay(week, weekDay, {
      weekStartsOn: 1
    })
  );

  const accessor = formatISODate(date);
  let isWithinPhaseRange = false;
  if (dates) {
    const { startAt: start, endAt: end } = dates;
    isWithinPhaseRange = start && end;
    if (isWithinInterval) {
      try {
        isWithinPhaseRange = isWithinInterval(date, { start, end });
      } catch (e) {
        isWithinPhaseRange = false;
      }
    }
  }
  return {
    Header: (
      <div>
        <div style={{ textAlign: 'center' }}>{`${header} ${printDate(date, 'dd')}`}</div>
        {showDayAndNight && (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                flexBasis: '50%'
              }}
            >
              Jour
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                flexBasis: '50%'
              }}
            >
              Nuit
            </div>
          </div>
        )}
      </div>
    ),
    accessor,
    className: isWithinPhaseRange ? 'phased' : null,
    width: dates ? '6%' : '5%',
    minWidth: showDayAndNight ? 120 : 75,
    dashed: false,
    isEditable: true,
    tooltip: isWithinPhaseRange ? "L'intervention se déroule ce jour" : null,
    Cell: ({ item, onChange }) => {
      let day = (item?.orderDays || {})[accessor];
      let safeAccessor = accessor;
      if (!day) {
        const dayKey = Object.keys(item?.orderDays || {}).find((key) => isSameDay(parseDate(key), date));
        if (dayKey) {
          day = item.orderDays[dayKey];
          safeAccessor = dayKey;
        }
      }
      if (!day) {
        day = {};
      }
      const dayValue = day.dayValue == null || day.dayValue === '' ? null : day.dayValue;
      const nightValue = day.nightValue == null || day.nightValue === '' ? null : day.nightValue;
      return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Dashed
            style={{
              display: 'flex',
              justifyContent: 'center'
            }}
          >
            <TextInputLayout
              variant={InputLayoutVariant.flat.medium}
              number
              value={dayValue}
              fillWidth
              fixedDecimalScale={false}
              onChange={(value) =>
                onChange({
                  ...item,
                  orderDays: {
                    ...item.orderDays,
                    [safeAccessor]: {
                      ...day,
                      dayValue: value == null || value === '' ? null : value
                    }
                  }
                })
              }
            />
          </Dashed>
          {showDayAndNight && (
            <Dashed
              style={{
                display: 'flex',
                justifyContent: 'center'
              }}
            >
              <TextInputLayout
                variant={InputLayoutVariant.flat.medium}
                number
                value={nightValue}
                fillWidth
                fixedDecimalScale={false}
                onChange={(value) =>
                  onChange({
                    ...item,
                    orderDays: {
                      ...item.orderDays,
                      [safeAccessor]: {
                        ...day,
                        nightValue: value == null || value === '' ? null : value
                      }
                    }
                  })
                }
              />
            </Dashed>
          )}
        </div>
      );
    }
  };
};

export const COLUMNS = (phaseDates, week, showDayAndNight) => [
  {
    Header: 'Centrale',
    accessor: 'purveyor',
    width: '15%',
    minWidth: 150,
    dashed: true,
    isEditable: true,
    Cell: ({ item, onChange }) => (
      <PurveyorSelect
        variant={InputLayoutVariant.flat.medium}
        value={item.purveyor}
        fillWidth
        label="Centrale"
        fetchDisabled
        onChange={(purveyor) => {
          const product = isSameIds(item.product?.purveyor?.id, purveyor?.id) ? item.product : null;
          onChange({
            ...item,
            purveyor,
            purveyorId: purveyor?.id || null,
            product,
            productId: product?.id || null
          });
        }}
      />
    )
  },
  {
    Header: "Formule d'enrobé",
    accessor: 'product',
    width: '36%',
    dashed: true,
    minWidth: 200,
    isEditable: true,
    Cell: ({ item, onChange }) => (
      <ProductSelect
        variant={InputLayoutVariant.flat.medium}
        label="Enrobé"
        value={item?.product}
        purveyorId={item.purveyor?.id || item.purveyorId}
        fillWidth
        fetchDisabled
        onChange={(product) => {
          const purveyor = product?.purveyor || item.purveyor;
          onChange({
            ...item,
            product,
            productId: product?.id || null,
            purveyor,
            purveyorId: purveyor?.id || null
          });
        }}
      />
    )
  },
  // {
  //   Header: 'Quantité',
  //   accessor: 'estimatedTotal',
  //   width: '7%',
  //   minWidth: 60,
  //   tooltip: 'Estimation du total nécessaire',
  //   dashed: true,
  //   isEditable: true,
  //   Cell: ({ item, onChange }) => (
  //     <TextInputLayout
  //       variant={InputLayoutVariant.flat.medium}
  //       number
  //       value={item.estimatedTotal}
  //       fillWidth
  //       decimalScale={1}
  //       onChange={(value) =>
  //         onChange({
  //           ...item,
  //           estimatedTotal: value
  //         })
  //       }
  //     />
  //   )
  // },
  getTonnageColumn('Lun', phaseDates, week, 1, showDayAndNight),
  getTonnageColumn('Mar', phaseDates, week, 2, showDayAndNight),
  getTonnageColumn('Mer', phaseDates, week, 3, showDayAndNight),
  getTonnageColumn('Jeu', phaseDates, week, 4, showDayAndNight),
  getTonnageColumn('Ven', phaseDates, week, 5, showDayAndNight),
  getTonnageColumn('Sam', phaseDates, week, 6, showDayAndNight),
  getTonnageColumn('Dim', phaseDates, week, 0, showDayAndNight),
  {
    Header: 'Total',
    accessor: 'total',
    width: '7%',
    minWidth: 80,
    textAlign: 'end',
    tooltip: 'Total réel de la commande',
    Cell: ({ item }) => (
      <TextInputLayout
        variant={InputLayoutVariant.flat.medium}
        number
        value={sum(
          Object.values(item.orderDays || {}).map((day) => {
            const dayValue = parseFloat(day?.dayValue) || 0;
            const nightValue = parseFloat(day?.nightValue) || 0;
            return dayValue + nightValue;
          })
        )}
        textAlign="end"
        fillWidth
        readOnly
      />
    )
  }
];

export const GLOBAL_VIEW_COLUMNS = (week, showDayAndNight) => [
  {
    Header: 'Chantier',
    accessor: 'project.displayName',
    width: '20%',
    minWidth: 100,
    Cell: ({ item }) => (
      <MultilineRow>
        <Text variant="small">{item.project?.displayName}</Text>
        <Text variant="caption" color="theme">
          {item.phase?.displayName}
        </Text>
      </MultilineRow>
    )
  },
  {
    Header: "Formule d'enrobé",
    accessor: 'product.displayName',
    width: '12%',
    minWidth: 100,
    Cell: ({ item }) => (
      <Tooltip content={item.product?.displayName}>
        <MultilineRow>
          <Text variant="small">{`AQP n°${item.product?.aqpNumber}`}</Text>
          <Text variant="caption" color="caption">
            {item.product?.markingFormulaCe}
          </Text>
        </MultilineRow>
      </Tooltip>
    )
  },
  {
    Header: 'N° de commande',
    accessor: 'orderNumber',
    width: '7%',
    dashed: true,
    isEditable: true,
    minWidth: 100,
    Cell: ({ item, onChange }) => (
      <TextInputLayout
        variant={InputLayoutVariant.flat.medium}
        textArea
        fillWidth
        value={item.orderNumber || ''}
        onChange={(orderNumber) =>
          onChange({
            ...item,
            orderNumber
          })
        }
      />
    )
  },
  // {
  //   Header: 'Quantité',
  //   accessor: 'estimatedTotal',
  //   width: '5%',
  //   minWidth: 100,
  //   textAlign: 'center',
  //   tooltip: "Estimation du total nécessaire pour l'intervention",
  //   Cell: ({ item }) => (
  //     <Text number variant="small" align-center="true">
  //       {item.estimatedTotal}
  //     </Text>
  //   )
  // },
  getTonnageColumn('Lun', null, week, 1, showDayAndNight),
  getTonnageColumn('Mar', null, week, 2, showDayAndNight),
  getTonnageColumn('Mer', null, week, 3, showDayAndNight),
  getTonnageColumn('Jeu', null, week, 4, showDayAndNight),
  getTonnageColumn('Ven', null, week, 5, showDayAndNight),
  getTonnageColumn('Sam', null, week, 6, showDayAndNight),
  getTonnageColumn('Dim', null, week, 0, showDayAndNight),
  {
    Header: 'Labo',
    accessor: 'labo',
    width: '8%',
    minWidth: 100,
    dashed: true,
    isEditable: true,
    Cell: ({ item, onChange }) => {
      const weekAccessor = formatISODate(week);
      const weekSettings = item.settings?.find((s) => s.week === weekAccessor) || {
        week: weekAccessor
      };
      const otherWeeksSettings = item.settings?.filter((s) => s.week !== weekAccessor) || [];
      return (
        <TextInputLayout
          variant={InputLayoutVariant.flat.medium}
          textArea
          fillWidth
          value={weekSettings.labo || ''}
          onChange={(labo) =>
            onChange({
              ...item,
              settings: [
                otherWeeksSettings,
                {
                  ...weekSettings,
                  labo
                }
              ]
            })
          }
        />
      );
    }
  },
  {
    Header: 'Rotation',
    accessor: 'rotation',
    width: '8%',
    minWidth: 100,
    dashed: true,
    isEditable: true,
    Cell: ({ item, onChange }) => {
      const weekAccessor = formatISODate(week);
      const weekSettings = item.settings?.find((s) => s.week === weekAccessor) || {
        week: weekAccessor
      };
      const otherWeeksSettings = item.settings?.filter((s) => s.week !== weekAccessor) || [];
      return (
        <TextInputLayout
          variant={InputLayoutVariant.flat.medium}
          textArea
          fillWidth
          value={weekSettings.rotation || ''}
          onChange={(rotation) =>
            onChange({
              ...item,
              settings: [
                otherWeeksSettings,
                {
                  ...weekSettings,
                  rotation
                }
              ]
            })
          }
        />
      );
    }
  },
  {
    Header: 'Total',
    accessor: 'total',
    width: '5%',
    minWidth: 100,
    textAlign: 'end',
    tooltip: 'Total réel de la commande',
    Cell: ({ item }) => {
      const total = sum(
        Object.values(item.orderDays || {}).map((day) => {
          const dayValue = parseFloat(day?.dayValue) || 0;
          const nightValue = parseFloat(day?.nightValue) || 0;
          return dayValue + nightValue;
        })
      );
      const warning = total !== parseFloat(item.estimatedTotal);
      return (
        <Tooltip
          content={warning ? 'Attention, la répartition des quantités ne correspond pas à la quantité prévue' : null}
        >
          <RepartitionText warning={warning}>
            <Text number variant="small">
              {total}
            </Text>
            {warning && (
              <Icon size="small">
                <MdWarning color="currentColor" />
              </Icon>
            )}
          </RepartitionText>
        </Tooltip>
      );
    }
  }
];

const OrderTable = ({ id, startAt, endAt, orders, onChange, globalView = false, readOnly, ...props }) => {
  const [currentWeek, setCurrentWeek] = useState(new Date());
  const dispatch = useDispatch();

  const ui = useSelector(UiSelector.selectUi);
  const withDayAndNight = ui?.orders?.showDayAndNight;

  const filteredOrders = useMemo(() => {
    return (orders || []).map((order) => {
      const { orderDays } = order;
      if (Array.isArray(orderDays)) {
        return {
          ...order,
          orderDays: orderDays.reduce((acc, { date, ...day }) => {
            acc[date] = day;
            return acc;
          }, {})
        };
      } else {
        return order;
      }
    });
  }, [orders]);

  const dates = useMemo(
    () => ({
      startAt: startAt ? parseDate(startAt) : null,
      endAt: endAt ? parseDate(endAt) : null
    }),
    [startAt, endAt]
  );

  const columns = useMemo(
    () =>
      globalView ? GLOBAL_VIEW_COLUMNS(currentWeek, withDayAndNight) : COLUMNS(dates, currentWeek, withDayAndNight),
    [dates, currentWeek, globalView, withDayAndNight]
  );

  useEffect(() => {
    const { startAt, endAt } = dates;
    if (!startAt) {
      return;
    }
    const now = new Date();
    if (isBefore(now, startAt) || isSameWeek(now, startAt, { weekStartsOn: 1 }) || !endAt) {
      setCurrentWeek(startOfWeek(dates.startAt, { weekStartsOn: 1 }));
    } else if (isBefore(dates.endAt, now) || isSameWeek(now, endAt, { weekStartsOn: 1 })) {
      setCurrentWeek(startOfWeek(dates.endAt, { weekStartsOn: 1 }));
    } else {
      setCurrentWeek(startOfWeek(now, { weekStartsOn: 1 }));
    }
  }, [dates]);

  const rowCreator = useCallback(() => {
    // on init les orderDays en attendant le fix du bug de l'issue 199
    let orderDays = [];
    try {
      let start = startOfDay(parseDate(startAt));
      let end = startOfDay(parseDate(endAt));
      orderDays = Array(1 + differenceInDays(end, start))
        .fill()
        .map((_, index) => {
          return {
            date: formatISODate(addDays(start, index)),
            dayValue: null,
            nightValue: null
          };
        });
    } catch (e) {
      console.warn(e);
    }
    return {
      ...orderRowCreator(),
      phaseId: id,
      orderDays
    };
  }, [id, startAt, endAt]);

  const hasDataBefore = useMemo(() => {
    return (
      !globalView &&
      filteredOrders.find((order) => {
        return Boolean(
          Object.keys(order.orderDays || {}).find((day) => {
            if (isBefore(parseDate(day), currentWeek)) {
              const orderDay = order.orderDays[day];
              const dayValue = parseFloat(orderDay?.dayValue) || 0;
              const nightValue = parseFloat(orderDay?.nightValue) || 0;
              return dayValue + nightValue > 0;
            } else {
              return false;
            }
          })
        );
      })
    );
  }, [globalView, filteredOrders, currentWeek]);

  const hasDataAfter = useMemo(() => {
    const endWeek = endOfWeek(currentWeek);
    return (
      !globalView &&
      filteredOrders.find((order) => {
        return Boolean(
          Object.keys(order.orderDays || {}).find((day) => {
            if (isAfter(parseDate(day), endWeek)) {
              const orderDay = order.orderDays[day];
              const dayValue = parseFloat(orderDay?.dayValue) || 0;
              const nightValue = parseFloat(orderDay?.dayValue) || 0;
              return dayValue + nightValue > 0;
            } else {
              return false;
            }
          })
        );
      })
    );
  }, [globalView, filteredOrders, currentWeek]);

  useEffect(() => {
    dispatch(SurfacingAction.getPurveyors({ withProducts: true }));
  }, [dispatch]);

  return (
    <Wrapper>
      {!globalView && (
        <FormTableTitle
          label="Commandes d'enrobés"
          asSection
          items={orders}
          readOnly={readOnly}
          onAddRow={() =>
            onChange([
              ...(orders || []),
              {
                ...rowCreator()
              }
            ])
          }
        >
          <DateNavigation
            leftActionTooltip=""
            rightActionTooltip=""
            dateIncrement={{
              weeks: 1
            }}
            hintLeftVisible={hasDataBefore}
            hintLeftTooltip="Une commande est prévue"
            hintRightVisible={hasDataAfter}
            hintRightTooltip="Une commande est prévue"
            date={currentWeek}
            dateTodayHidden
            dateAlignment="week"
            onDateChanged={(date) => {
              setCurrentWeek(parseDate(date));
            }}
            datePickerProps={{
              value: formatISODate(currentWeek),
              asWeekSelect: true,
              fitContent: true,
              isClearable: false
            }}
          />
        </FormTableTitle>
      )}
      <FormTable
        {...props}
        columns={columns}
        useWindow={false}
        rowWrap={false}
        value={filteredOrders}
        onChange={onChange}
        minHeight={50}
        isRowValid={isRowValid}
        readOnly={readOnly}
      />
    </Wrapper>
  );
};

export default OrderTable;
